<template>
  <div>
    <vue-content-loading :width="300" :height="114">
      <rect x="0" y="0" rx="1" ry="1" width="100%" height="293" />
    </vue-content-loading>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'
export default {
  components: {
    VueContentLoading,
  },
}
</script>

<style lang="scss" scoped></style>
